.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(to right, pink, yellow);
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
}

.App-footer {
  background: linear-gradient(to right, pink, yellow);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}